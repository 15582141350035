module.exports = {
  siteTitle: "Cooking and coding - A blog by Markus Vilhelm Seppälä", // <title>
  shortSiteTitle: "Cooking and coding", // <title> ending for posts and pages
  siteDescription: "A blog about life long learning in cooking and coding",
  siteUrl: "https://cc.markusvilhelmseppala.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Markus Vilhelm Seppälä",
  authorTwitterAccount: "markusvilhelm",

  /* info */
  headerTitle: "Cooking and Coding",
  headerSubTitle: "Cooking and Coding",

  /* manifest.json */
  manifestName: "Cooking and Coding",
  manifestShortName: "CCMVS", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "https://www.gravatar.com/avatar/69e11b16ee6bc6895cce15214aa20fda",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/markusvilhelm" },
    { name: "twitter", url: "https://twitter.com/markusvilhelm" },
    { name: "facebook", url: "http://facebook.com/seppala" }
  ]
};
